import { useEffect, useState } from 'react';
import { Divider, Grid, Skeleton } from '@mui/material';
import algoliasearch from 'algoliasearch';
import {
  Configure,
  InstantSearch,
  useHits,
} from 'react-instantsearch-hooks-web';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AlgoliaFilter, BottomDrawer, ResultNotFound } from '../../components';
import { algolia, environment } from '../../env';
import { RouteStateType } from '../../interface';
import { SuperSEO } from 'react-super-seo';
import { pageSEO } from '../../seo';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { authAction } from '../../store';
import Context from '../../context';
import { ProductDetailsPlaceHolder } from '../../assets/images';
import { _currencyFormatter } from '../../utils/Index';
import GetGuidance from '../Specialities/GetGuidance';
import SpecialitiesBooking from '../Specialities/SpecialitiesBooking';
import ElevateAppBar from '../../components/commons/CompareNavBar';
import ButtonDefault from '../../components/commons/Button/ButtonDefault';
import { pathBuilder } from '../../urlPaths';
import { _clickAnalyticsUpdate } from '../../utils/API_SERVICE/_concierge';

const CategoriesCard = (props: any) => {
  const navigate = useNavigate();
  const isAuthenticated = useSelector(
    (state: any) => state.auth.isAuthenticated
  );
  const dispatch = useDispatch();
  let { hits }: any = useHits();

  hits = hits.filter((item: any, index: number) => {
    return (
      hits.findIndex(
        (fItem: any) =>
          item.item_details.compare_id === fItem.item_details.compare_id &&
          item.active
      ) === index ||
      (item.item_details.comparable === false && item.active)
    );
  });

  const [drawerState, setDrawerState] = useState<boolean>(false);
  const [serviceDrawer, setSericeDrawer] = useState<boolean>(false);
  const [currentItem, setCurrentItem] = useState<any>();

  const navigateToGuidence = (item: any) => {
    if (item?.landing_page_url && !drawerState) {
      window.open(`${item.landing_page_url}`);
    } else {
      if (isAuthenticated) {
        setDrawerState(!drawerState);
        if (!drawerState) {
          setCurrentItem(item);
        }
      } else {
        dispatch(authAction.accesspermisson());
      }
    }
  };

  const serviceBookingHandler = (data: any) => {
    if (isAuthenticated) {
      if (!serviceDrawer) {
        setCurrentItem(data);
      }
      setSericeDrawer(!serviceDrawer);
    } else {
      dispatch(authAction.accesspermisson());
    }
  };

  const DetailsClickHandler = (item: any) => {
    let item_type = item.item_details.item_type.toLowerCase();
    let group_name = item.group.name.toLowerCase();
    let compare_count = item.item_details?.compare_count ?? 0;

    if (item_type === 'specialist') {
      !(item.item_details?.comparable ?? false)
        ? navigate(
            pathBuilder.specialist(item?.slug)
          )
        : navigate(pathBuilder.compare(item.item_details?.compare_slug));
    } else if (item_type === 'packages') {
      compare_count === 1
        ? navigate(pathBuilder.packageDetails(item.slug))
        : navigate(pathBuilder.compare(item.item_details?.compare_slug));
    } else if (group_name === 'medical supplies') {
      compare_count === 1
        ? navigate(pathBuilder.productDetails(item.slug))
        : navigate(pathBuilder.compare(item.item_details?.compare_slug));
    } else if (item_type === 'services') {
      compare_count === 1
        ? navigate(pathBuilder.serviceDetails(item.slug))
        : navigate(pathBuilder.compare(item.item_details?.compare_slug));
    }

    _clickAnalyticsUpdate({
      item_id: item.id,
      item_type: item.item_details?.item_endpoint,
    })
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  const GetButtonLabel = (item: any) => {
    let item_type = item.item_details?.item_type.toLowerCase();
    let group_name = item.group.name.toLowerCase();
    let compare_count = item.item_details?.compare_count ?? 0;

    if (item_type === 'specialist')
      return !(item.item_details?.comparable ?? false)
        ? 'Book Specialist'
        : 'Compare Specialists';
    if (item_type === 'packages')
      return compare_count === 1 ? 'View Package' : 'Compare Packages';
    if (item_type === 'products')
      return compare_count === 1 ? 'View Product' : 'Compare Products';
    if (group_name === 'medical supplies')
      return compare_count === 1 ? 'Buy Now' : 'Compare Now';

    return compare_count === 1 ? 'View Details' : 'Compare Now';
  };

  return (
    <Grid container spacing={2.5}>
      {hits.map((item: any, index: number) => {
        return (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <div className="service-item-container h-100 d-flex flex-column">
              <div className="d-flex p-2">
                <div className="mr-2 specialities-card-img">
                  <img
                    src={
                      item.images?.main
                        ? item.images?.main
                        : ProductDetailsPlaceHolder
                    }
                    alt=""
                  />
                </div>
                <div>
                  {/* {
                      !['Products', 'Services'].includes(item?.item_details?.item_type) ? 
                      <h6 className='fs-15 font-weight_5 package-heading-clamp'> {item.provider_details?.name}</h6>
                      :
                    } */}
                  <h6 className="fs-15 font-weight_5 package-heading-clamp">
                    {' '}
                    {item.name}
                  </h6>
                  <p className="fs-12">{props.service}</p>
                  <p className="fs-10 mt-1">{props.options}</p>
                  {item.item_details?.item_type !== 'Products' && (
                    <>
                      {item.item_details.comparable && (
                        <p>
                          {item.item_details.compare_count} Options Available
                        </p>
                      )}
                    </>
                  )}
                  <p className="fs-14 color_primary-light font-weight_6">
                    {_currencyFormatter(
                      item.rates.facility_visit ||
                        item.rates.home_visit ||
                        item.rates.teleconsult ||
                        item.rates.emergency_rate
                    )}
                  </p>
                  {item.provider_details?.qualifications ? (
                    <>
                      <p className="fs-13 color_dark-grey mt-2">
                        Qualification
                      </p>
                      <p>{item.provider_details.qualifications}</p>
                    </>
                  ) : null}
                  {item.provider_details?.standalone_type ? (
                    <>
                      <p className="fs-13 color_dark-grey mt-2">
                        Standalone type
                      </p>
                      <p>{item.provider_details.standalone_type}</p>
                    </>
                  ) : null}
                </div>
              </div>
              <div className="mt-auto">
                <div className="p-0 px-3 pb-4 d-flex">
                  <div className="btn-section">
                    <ButtonDefault
                      btnClass={'bg_transparent border_primary w-100'}
                      title={'Get Guidance'}
                      classes={'mt-2 w-100 mx-auto'}
                      click={navigateToGuidence.bind('ev', item)}
                    />

                    <ButtonDefault
                      title={GetButtonLabel(item)}
                      classes="ml-2 mt-2 w-100 mx-auto"
                      click={() => DetailsClickHandler(item)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        );
      })}
      {drawerState && (
        <BottomDrawer state={drawerState} click={navigateToGuidence}>
          <GetGuidance currentItem={currentItem} click={navigateToGuidence} />
        </BottomDrawer>
      )}
      {serviceDrawer && (
        <Context.Provider
          value={{
            data: {
              state: serviceDrawer,
              click: serviceBookingHandler,
              info: { ...currentItem },
              authenticated: isAuthenticated,
            },
          }}
        >
          <BottomDrawer state={serviceDrawer} click={serviceBookingHandler}>
            <SpecialitiesBooking />
          </BottomDrawer>
        </Context.Provider>
      )}
    </Grid>
  );
};

const Loading = () => {
  const { results } = useHits();
  return (
    <>
      {results!.processingTimeMS <= 0 && (
        <Grid item xs={12} sm={6} md={4}>
          <div className="service-item-container h-100 d-flex flex-column">
            <div className="d-flex p-2 h-100">
              <div className="mr-2 specialities-card-img">
                <Skeleton
                  variant="rectangular"
                  width={'100%'}
                  height={'100%'}
                ></Skeleton>
              </div>
              <div className="w-100">
                <Skeleton
                  variant="rectangular"
                  width={'100%'}
                  height={15}
                ></Skeleton>
                <Skeleton
                  variant="rectangular"
                  width={'70%'}
                  className="my-3"
                  height={10}
                ></Skeleton>
                <Skeleton
                  variant="rectangular"
                  width={'80%'}
                  height={10}
                ></Skeleton>
              </div>
            </div>
            <div className="mt-auto">
              <div className="d-flex mt-1">
                <Skeleton
                  variant="rectangular"
                  width={'100%'}
                  height={35}
                ></Skeleton>
              </div>
            </div>
          </div>
        </Grid>
      )}
    </>
  );
};

export default function SearchResultDetails(props: any) {
  const { state } = useLocation();
  const { searchId } = useParams();
  const [searchTerm, setSearchTerm] = useState('');

  let pageTitle: string = searchId
    ?.replaceAll('-', ' ')
    .toUpperCase() as string;
  let searchAbleKey: string = '';

  searchAbleKey = pageTitle;
  const searchClient = algoliasearch(algolia.appId, algolia.apiKey);

  let pageInfo: RouteStateType = {
    title: '',
    icon: '',
  };

  pageInfo = state as RouteStateType;

  type TPageSeo = keyof typeof pageSEO.searchItems;
  let seoItems = searchId as TPageSeo;

  useEffect(() => {
    if (searchAbleKey) {
      setSearchTerm(searchAbleKey);
    }
  }, [searchAbleKey]);

  if (searchTerm !== searchAbleKey) {
    return (
      <>
        <SuperSEO
          title={
            pageSEO.searchItems[seoItems]
              ? pageSEO.searchItems[seoItems]?.title
              : pageSEO.search.title
          }
          description={
            pageSEO.searchItems[seoItems]
              ? pageSEO.searchItems[seoItems]?.description
              : pageSEO.search.description
          }
        />

        <section className="mb-6">
          <Divider />

          <InstantSearch
            searchClient={searchClient}
            indexName={environment.algoliaIndex || 'product_index_newest'}
            searchFunction={(helper) => {
              helper.setQuery(searchAbleKey).search();
            }}
          >
            <Configure hitsPerPage={50000} filters="active: true" />
            <ElevateAppBar>
              <AlgoliaFilter
                classes="py-4"
                btnClass="color_primary"
                filterBtnClass="d-flex"
              >
                {pageInfo?.icon && (
                  <img
                    src={pageInfo?.icon}
                    alt=""
                    style={{ width: '60px', height: 'auto' }}
                  />
                )}
                {pageTitle && (
                  <p className="color_primary-light fs-22 pl-2">{pageTitle}</p>
                )}
              </AlgoliaFilter>
            </ElevateAppBar>
            <CategoriesCard />
            <Loading />
            <ResultNotFound />
          </InstantSearch>
        </section>
      </>
    );
  } else {
    return (
      <>
        <SuperSEO
          title={
            pageSEO.searchItems[seoItems]
              ? pageSEO.searchItems[seoItems]?.title
              : pageSEO.search.title
          }
          description={
            pageSEO.searchItems[seoItems]
              ? pageSEO.searchItems[seoItems]?.description
              : pageSEO.search.description
          }
        />

        <section className="mb-6">
          <Divider />

          <InstantSearch
            searchClient={searchClient}
            indexName={environment.algoliaIndex || 'product_index_newest'}
            searchFunction={(helper) => {
              helper.setQuery(searchAbleKey).search();
            }}
          >
            <Configure hitsPerPage={50000} />
            <ElevateAppBar>
              <AlgoliaFilter
                classes="py-4"
                btnClass="color_primary"
                filterBtnClass="d-flex"
              >
                {pageInfo?.icon && (
                  <img
                    src={pageInfo?.icon}
                    alt=""
                    style={{ width: '60px', height: 'auto' }}
                  />
                )}
                {pageTitle && (
                  <p className="color_primary-light fs-22 pl-2">{pageTitle}</p>
                )}
              </AlgoliaFilter>
            </ElevateAppBar>
            <CategoriesCard />
            <Loading />
            <ResultNotFound />
          </InstantSearch>
        </section>
      </>
    );
  }
}
